import React, { useState, useEffect, useContext } from 'react';


import SimpleTable from '../../components/tables/simpleTable';

import RecoverModal from '../../components/modals/recoverModal';
import AddModal from '../../components/modals/addModal';
import EditModal from '../../components/modals/editModal';
import DeleteModal from '../../components/modals/deleteModal';
import CartModal from '../../components/modals/cartModal';

import SearchFilter from '../../components/filters/search';
import CustomButton from '../../components/buttons/customButton';
import IsActiveFilter from '../../components/filters/isActive';
import AddButton from '../../components/buttons/addButton';
import DataFilter from '../../components/filters/dataFilter';
import DatePicker from '../../components/filters/datePicker';
import AsyncSelect from 'react-select/async';

import { FcKindle } from "react-icons/fc";

import Loader from '../../components/loader/loader';
import { numberFormatter, totalFormatter, decimalFormatter } from '../../components/formatters/numberFormatter';
import { dateFormatter } from '../../dynamic/dateFormatter';
import { typeFormatter } from '../../components/formatters/defFormatter';
import { clientOrderColumnDefs } from '../../components/tables/columnDefs';
import { dateTableFormatter } from '../../dynamic/dateFormatter';
import toast from 'react-hot-toast';

import { Space, Input, Tag, Button, Tabs } from 'antd';

import Select from 'react-select';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';


import { FiEdit } from "react-icons/fi";
import { FcUndo, FcRefresh, FcFlowChart, FcRules } from "react-icons/fc";
import { BiSolidTrash, BiBarcodeReader } from "react-icons/bi";
import { IoBarcodeOutline } from "react-icons/io5";
import { FcPlus } from "react-icons/fc";
import { HiMinusCircle } from "react-icons/hi";

import { DataContext } from '../../init/getData';




function Production(props) {


  const { api, userData, settingData, refProductData, productData, accountData, refAccountData, refWareHouseData, wareHouseData } = useContext(DataContext);
  const userRole = userData.role;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const createdBy = parseInt(userData.id);


  useEffect(() => {
    const fetchData = async () => {
      try {
        await refProductData();
        await refAccountData();
        await refWareHouseData();
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    fetchData();
  }, []);

  const table = 'transaction';
  const title = 'Production';

  const inStockProducts = productData.filter(product => product.type === 'production');

  // Product Options
  const productOptions = inStockProducts?.map(item => ({
    value: item.id,
    label: `${item.sku ? ` (${item.sku}) ` : ''}  ${item.name} ${item.barcode ? ` [${item.barcode}]` : ''}`,
    id: item.id,
    category: item.category.name,
    productId: item.id,
    type: item.type,
    name: item.name,
    barcode: item.barcode,
    cost: item.cost,
    price: item.price,
    isStock: item.isStock,
    stock: item.totalStock,
    units: item.units,
    unit: item.unit,
  }))


  // WareHouse Options
  const wareHouseOptions = wareHouseData?.map(item => ({
    value: item.id,
    label: item.name,
  }))

  const today = new Date().toISOString().split('T')[0];

  const [idRow, setIdRow] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filter, setFilter] = useState({ search: '', isActive: 'true', type: ['Production'], fromDate: today, toDate: today });


  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);

  const [supplier, setSupplier] = useState([]);
  const [wareHouse, setWareHouse] = useState([]);
  const [transaction, setTransaction] = useState({});

  const [cart, setCart] = useState([]);

  const cartItem = {
    productId: '',
    category: '',
    barcode: '',
    name: '',
    quantity: 1,
    price: 0,
    netPrice: 0,
    totalPrice: 0,
    cost: 0,
    isStock: true,
    type: '',
    units: JSON.stringify([]),
    unit: '',
    unitFormula: 1,
    unitQuantity: 1,
    details: '',
  };


  // idRow Change
  useEffect(() => {

    const wareHouseId = wareHouseOptions.find(option => option.value === idRow?.wareHouseId) || [];
    setWareHouse(wareHouseId);

    setCart(idRow?.cart || [cartItem])

    setTransaction(
      {
        createdBy: createdBy,
        wareHouseId: idRow?.wareHouseId || [],
        date: idRow?.date || today,
        isOut: false,
        isCredit: false,
        type: 'Production',
        cost: idRow?.cost || 0,
        details: idRow?.details || '',
      }
    )
  }, [idRow]);


  // Add new row
  const handleAddRow = () => {
    setCart([...cart, cartItem]);
  };

  // Delete VarientRow
  const handleDeleteRow = (event) => {
    event.preventDefault()
    if (cart.length > 1) {
      const updatedRows = cart.slice(0, -1);
      setCart(updatedRows);
    } else {
      toast.error('you need at least one item')
    }
  };

  // handle row changes
  const handleRowChange = (index, value, field) => {
    const updatedCart = [...cart];


    if (field == 'product') {
      updatedCart[index].productId = value.id;
      updatedCart[index].name = value.name;
      updatedCart[index].barcode = value.barcode;
      updatedCart[index].cost = parseFloat(value.cost);
      updatedCart[index].price = parseFloat(value.price);
      updatedCart[index].netPrice = parseFloat(value.price);
      updatedCart[index].category = value.category;
      updatedCart[index].type = value.type;
      updatedCart[index].unit = value.unit;
      updatedCart[index].units = JSON.stringify(value.units);
      updatedCart[index].unitFormula = 1;
      updatedCart[index].unitQuantity = 1;

    } else if (field == 'price') {

      updatedCart[index].price = parseFloat(value);
      updatedCart[index].netPrice = parseFloat(value);

    } else if (field == 'quantity') {
      updatedCart[index].quantity = value;

    } else if (field == 'unitFormula') {
      updatedCart[index].unitFormula = value;

    } else if (field == 'unitQuantity') {
      updatedCart[index].unitQuantity = value;

    } else if (field == 'details') {
      updatedCart[index].details = value;
    }


    if (settingData.showSecondaryUnit) {
      updatedCart[index].quantity = updatedCart[index].unitFormula * updatedCart[index].unitQuantity;
    }

    updatedCart[index].totalPrice = decimalFormatter(updatedCart[index].price * updatedCart[index].quantity, 2);

    setCart(updatedCart);
  }


  // calculate Total
  useEffect(() => {
    const cost = cart.reduce((acc, item) => acc += parseFloat(item.cost * item.quantity), 0);
    setTransaction((prev) => ({
      ...prev,
      cost: cost,
    }));
  }, [cart]);


  const addModal = () => {
    setIdRow([]);
    resetForm();
    setShowAddModal(!showAddModal);
  }

  const cartModal = (e) => {
    if (!showCartModal) {
      setIdRow(e);
    }
    setShowCartModal(!showCartModal);
  }

  const recoverModal = (e) => {
    if (!showRecoverModal) {
      setIdRow(e);
    }
    setShowRecoverModal(!showRecoverModal);
  }

  const deleteModal = (e) => {
    if (!showDeleteModal) {
      setIdRow(e);
    }
    setShowDeleteModal(!showDeleteModal);
  }

  const editModal = (e) => {
    if (!showEditModal) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }


  const resetForm = () => {
    setIdRow([]);
    setSupplier([]);
    setWareHouse([]);
    setCart([cartItem]);
  }

  // handle select change
  const handleSelectChange = (selectedOption, name) => {
    if (name == 'accountId') {
      setSupplier(selectedOption)
    } else if (name == 'wareHouseId') {
      setWareHouse(selectedOption)
    }
    setTransaction((prev) => ({
      ...prev,
      [name]: selectedOption.value,
    }));
  };

  // Generic handler for input changes
  const handleInputChange = (e, type) => {
    let { name, value } = e.target;
    if (type == 'number') {
      value = parseFloat(value)
    } else if (type == 'boolean') {
      value = JSON.parse(value)
    }
    setTransaction((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const refreshTableFunction = () => {
    setRefreshTable(!refreshTable)
  }

  const filterProducts = (inputValue, options) => {
    return options.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadProductOptions = (inputValue, callback) => {
    if (inputValue) {
      callback(filterProducts(inputValue, productOptions));
    } else {
      callback([]);
    }
};

  //Excel Data
  const getExcelData = (data) => {

    const transformedData = [];
    data.forEach(item => {

      const newItem = {
        id: item.id,
        createdAt: item.createdAt,
        account: item.account?.name,
        delivery: item.delivery?.name,
        cost: item.cost,
        type: item.type,
        isCredit: item.isCredit,
        details: item.details,
      };

      transformedData.push(newItem);
    });

    return { transformedData };
  };

  let newColumnDefs = [
    { title: 'ID', dataIndex: 'id', width: '6%' },
    { title: 'CreatedAt', dataIndex: 'createdAt', render: dateTableFormatter },
    { title: 'Date', dataIndex: 'date' },
    { title: 'Type', dataIndex: 'type', render: typeFormatter },
    { title: 'Cost', dataIndex: 'cost', width: '8%', render: numberFormatter },
  ];

  useEffect(() => {

    if (filter.isActive == 'true') {
      newColumnDefs.push(
        {
          title: 'View',
          width: '5%',
          render: (_, record) => (
            <FcKindle size={18} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => cartModal(record)} />
          ),
        },
        {
          title: 'Edt',
          width: '5%',
          render: (_, record) => (
            <FiEdit size={16} style={{ cursor: 'pointer', color: '#2E70A5' }} onClick={() => editModal(record)} />
          ),
        },
        {
          title: 'Del',
          width: '5%',
          render: (_, record) => (
            <BiSolidTrash size={18} style={{ cursor: 'pointer', color: '#e6434f' }} onClick={() => deleteModal(record)} />
          ),
        }
      );
    } else {
      newColumnDefs.push({
        title: 'Rec',
        width: '3%',
        render: (_, record) => (
          <FcUndo size={20} style={{ cursor: 'pointer' }} onClick={() => recoverModal(record)} />
        ),
      });
    }
    setColumnDefs(newColumnDefs);

  }, [filter.isActive]);

  const formView = (
    <div style={{ userSelect: 'none' }}>
      <>

        <Row className="mb-3" >


          {/* Date */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>Date<span style={{ color: 'red' }}> *</span></Form.Label>
            <input type="date" className="form-control" name='date'
              onChange={(e) => handleInputChange(e, 'text')}
              value={transaction.date}
              required
            ></input>
          </Form.Group>


          {/* WareHouse */}
          <Form.Group as={Col}>
            <Form.Label className='formLabel'>WareHouse<span style={{ color: 'red' }}> *</span></Form.Label>
            <Select
              options={wareHouseOptions}
              isSearchable={true}
              placeholder="Select"
              onChange={(e) => handleSelectChange(e, 'wareHouseId')}
              value={wareHouse}
              required
            />
          </Form.Group>


        </Row>


        {/* Cart Array  */}
        <div>
          {cart.map((row, index) => (
            <div key={index} style={{ marginTop: '2%' }}>
              {/* Labels */}
              {index === 0 && (
                <Row className="mb-3">
                  <Form.Group as={Col} md={4}>
                    <Form.Label>Select Products</Form.Label>

                    <FcRefresh size={20} onClick={refProductData}
                      style={{ cursor: 'pointer', marginLeft: '5' }} />
                  </Form.Group>

                  {settingData.showSecondaryUnit &&
                    <Form.Group as={Col} >
                      <Form.Label>unit</Form.Label>
                    </Form.Group>
                  }

                  {settingData.showSecondaryUnit &&
                    <Form.Group as={Col}   >
                      <Form.Label>Unit Quantity</Form.Label>
                    </Form.Group>
                  }

                  <Form.Group as={Col}   >
                    <Form.Label>Quantity</Form.Label>
                  </Form.Group>

                  <Form.Group as={Col} md={2}>
                    <Form.Label>Details</Form.Label>
                  </Form.Group>

                </Row>

              )}

              {/* Products */}
              <Row className="mb-3" style={{ marginTop: '-20px' }}>
                <Form.Group as={Col} md={4}>
                  {/* 
                  <Select
                    options={productOptions}
                    isSearchable={true}
                    placeholder="Select"
                    onChange={(e) => handleRowChange(index, e, 'product')}
                    value={productOptions.find(option => option.value === cart[index].productId) || []}
                    required     
                  />
                      */}

                    <AsyncSelect
                    cacheOptions
                    loadOptions={loadProductOptions}
                    defaultOptions={[]}
                    onChange={(e) => handleRowChange(index, e, 'product')}
                    value={productOptions.find(option => option.value === cart[index].productId) || []}
                    noOptionsMessage={() => 'type to view'}
                    required
                  />

                </Form.Group>


                {settingData.showSecondaryUnit &&
                  <>
                    {/* Secondary Units  */}
                    <Form.Group as={Col} >
                      <select className="form-control" name='unitFormula'
                        value={cart[index].unitFormula}
                        onChange={(e) => handleRowChange(index, e.target.value, 'unitFormula')}>
                        <option value={1}>{cart[index].unit} (x1)</option>
                        {(cart[index]?.units ? JSON.parse(cart[index].units) : []).map((unit, unitIndex) => (
                          <option key={index} value={unit.value}>{unit.unit} (x{unit.value})</option>
                        ))}

                      </select>
                    </Form.Group>

                    {/*  Units Quantity  */}
                    <Form.Group as={Col} >
                      <Form.Control type="number" min={0} step='any'
                        onChange={(e) => handleRowChange(index, e.target.value, 'unitQuantity')}
                        value={cart[index].unitQuantity}
                        required
                      />
                    </Form.Group>  

                    {/* Quantity by Unit  */}
                    <Form.Group as={Col} >
                      <Form.Control type="number" min={0} step='any' disabled readOnly
                        value={cart[index].quantity} />
                    </Form.Group>
                  </>
                }


                {/* Quantity by Unit  */}
                {!settingData.showSecondaryUnit &&
                  <Form.Group as={Col} >
                    <Form.Control type="number" min={0} step='any'
                    onChange={(e) => handleRowChange(index, e.target.value, 'quantity')}
                      value={cart[index].quantity} />
                  </Form.Group>
                }


                {/* Details */}
                <Form.Group as={Col} md={2}>
                  <Form.Control as="textarea" rows={1}
                    value={cart[index].details}
                    onChange={(e) => handleRowChange(index, e.target.value, 'details')}
                  />
                </Form.Group>

              </Row>



              <Row key={index + 100} className="mb-3">
                {index === cart.length - 1 && (
                  <Form.Group
                    as={Col}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}
                  >
                    <FcPlus size={26} style={{ cursor: 'pointer' }} onClick={handleAddRow} />
                    <HiMinusCircle size={28} style={{ cursor: 'pointer' }} onClick={(e) => handleDeleteRow(e)} />

                  </Form.Group>
                )}
              </Row>
            </div>
          ))}
        </div>



        <Row className="mb-3" style={{ marginTop: '2%', textAlign: 'center' }}>
          <Form.Group as={Col}>
            <Form.Label>Details</Form.Label>
            <textarea className="form-control"
              value={transaction.details} name='details'
              onChange={(e) => handleInputChange(e, 'text')}
            ></textarea>
          </Form.Group>

        </Row>



      </>


    </div >
  )

  const tableFooter = (
    <></>
  )

  const modalFooter = (
    <div>

      <a style={{ fontWeight: 'bold' }}>
        Total Cost
      </a>

      <a style={{ color: 'green', fontWeight: 'bold', fontSize: '25px', marginLeft: 100 }}>
        {numberFormatter(transaction.cost, 2)}{settingData.currency}
      </a>

    </div>
  )

  return (
    <div className='card'>

      {isLoading &&
        <Loader />
      }


      {!isLoading &&
        <>
          <div>

            <SearchFilter setFilter={setFilter} />
            <DatePicker setFilter={setFilter} filter={filter} />
            <AddButton addModal={addModal} />

          </div>

          <SimpleTable
            filter={filter}
            showAddModal={showAddModal}
            addModal={addModal}
            table={table}
            title={title}
            columnDefs={columnDefs}
            idRow={idRow}
            setIdRow={setIdRow}
            refreshTable={refreshTable}
            data={data}
            setData={setData}
            tableFooter={tableFooter}
            api={api}
            getExcelData={getExcelData}
          />

          <DeleteModal
            showModal={showDeleteModal}
            toggleModal={deleteModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            data={{ transactionId: idRow?.id, cart, transaction: transaction }}
          />

          <CartModal
            showModal={showCartModal}
            toggleModal={cartModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
            showCost={true}
          />

          <RecoverModal
            showModal={showRecoverModal}
            toggleModal={recoverModal}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            api={api}
          />

          <AddModal
            showModal={showAddModal}
            toggleModal={addModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'xxl'}
            resetForm={resetForm}
            footer={modalFooter}
            api={api}
            data={{ cart, transaction: transaction }}
          />

          <EditModal
            showModal={showEditModal}
            toggleModal={editModal}
            formView={formView}
            idRow={idRow}
            table={table}
            title={title}
            refreshTable={refreshTableFunction}
            size={'xxl'}
            footer={modalFooter}
            api={api}
            data={{ transactionId: idRow?.id, cart, transaction: transaction }}
          />


        </>
      }

    </div>
  )
}

export default Production;
